import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {reo1Color} from "../../Constants";
import {useTranslation} from 'react-i18next';
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';

const ProductCard = (props) => {
    const {t} = useTranslation();

    return (
    <Link to={"/shop/product?id="+ props.id}>
    <Card sx={{ cursor: 'pointer', borderRadius: 5, m: 1}}>
        <Box sx={{ position: 'relative' }}>
        <img src={props.images[0]} alt="Logo"  style={{height: 400, width: "100%", objectFit: 'cover'}}/>
        <Box
            sx={{
                position: 'absolute',
                top: 150,
                right: 5,
                width: '100%',
                alignItems: 'flex-end',
                textAlign: 'right',
                color: 'black',
                padding: '10px',
            }}
            >
            
        </Box>
        <Box p={2} minHeight={120}>
            <Stack direction="row" minHeight={80}>
                <Typography variant="h5" width="70%">
                    {props.productName}
                </Typography>
                <Box alignContent="self-end"  width="30%" textAlign="right">
                    <Typography variant="h5" textAlign="right">{props.productPrice}</Typography>
                </Box>
            </Stack>
            <Typography variant="body2" color="text.secondary">
                {t('shop.productCardFrom')} {props.companyName}
            </Typography>
        </Box>
      </Box>
    </Card>
    </Link>
  );
}
export default ProductCard;