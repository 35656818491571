import React, { Component } from "react";
import { FiSmartphone , FiLayers , FiCpu , FiAward, FiTrash} from "react-icons/fi";
import { useTranslation, withTranslation, Trans } from 'react-i18next';



class ServiceTwo extends Component{
    render(){
        const { t } = this.props;
        const ServiceList = [
            {
                icon: <FiSmartphone />,
                title: t('home.productsApp'),
                description: t('home.productsAppDescription'),
                link: "/eco-superapp"
            },
        
            {
                icon: <FiLayers />,
                title: t('home.products3D'),
                description: t('home.products3DDescription'),
                link: "/3d-printing"
            },
            {
                icon: <FiAward />,
                title: t('home.productsDSubbotnik'),
                description: t('home.productsDSubbotnikDescription'),
                link: "/digital-subbotnik"
            },
            {
                icon: <FiTrash />,
                title: t('home.productRVM'),
                description: t('home.productRVMDescription'),
                 link: "/rvm"
            },
        ]

        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{t('home.products')}</h2>
                            <p>{t('home.productsDescription')}</p>
                            {/* <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.link}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(ServiceTwo);
