import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class SliderOne extends Component {
  render() {
    const { t } = this.props;
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center" data-black-overlay="6" style={{backgroundColor: "#6b8090"}}>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient">{t('home.pageTitle')}</h1>
                            </div>
                        </div>
                    </div>
                    {/* Start Service Area */}
                    <div className="service-wrapper service-white">
                        <ServiceOne />
                    </div>
                    {/* End Service Area */}
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}

export default withTranslation()(SliderOne);