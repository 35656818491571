import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import testimonialImg1 from "../assets/images/client/vardan.png";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class Testimonial extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="section-title">
                    <p>
                      {t('home.testimonialsText')}
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>{t('home.testimonialsAuthor')} </span> - {t('home.testimonialsAuthorTitle')} 
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg1} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Testimonial);
