import * as React from 'react';
import { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { FiShoppingCart } from "react-icons/fi";
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import { useNavigate } from "react-router-dom";

const CartIcon = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [productCount, setProductCount] = useState(0);

    useEffect(() =>{
        try{
            let val = props.cart.length;
            setProductCount(val)
        }
        catch
        {
            //console.log("Cart parse error")
        }
    },[props])

    return (
        <>
        {productCount >= 0
        ? <Badge color="success" badgeContent={productCount} >
            <Box 
                onClick={() => {productCount> 0 ? navigate(`${process.env.PUBLIC_URL}/shop/cart`): console.log("No products in cart")}}
                sx={{
                    width: 60,
                    height: 60,
                    borderRadius: 6,
                    backgroundColor: "#1ce783",
                    alignContent: "center",
                    cursor: 'pointer' 
                }}
                display="flex"
                justifyContent="center"
                >
                <Box mt={2}>
                <FiShoppingCart/>
                </Box>
            </Box>
            </Badge>
        :<></>}
        </>
  );
}

export default CartIcon;