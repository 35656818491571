import * as React from 'react';
import {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const ColorPicker = (props) => {

    const [selectedColor, setSelectedColor] = useState(props.colors[0]);

    useEffect(() =>{
        //console.log(props.colors)
        props.setSelectedColor(props.colors[0]);
        setSelectedColor(props.colors[0])
    },[])

    const handleButtonClick = (color) =>{
        props.setSelectedColor(color); 
        setSelectedColor(color)
    }

    return (
    <Stack direction="row" spacing={2} mt={1} mb={3}>
        {props.colors.map((color) =>
            <Box
                key={color}
                sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    border: 6,
                    borderColor: color== selectedColor ? color : "white",
                    backgroundColor: color
                }}
                onClick={() => handleButtonClick(color)}
            >
            </Box>
        )}
    </Stack>
  );
}

export default ColorPicker;