import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class PageHelmet extends Component{
    render(){
        const { t } = this.props;
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                    <meta name="description" content={t('common.description')}/>
                    <meta name="keywords" content={t('common.keywords')} />
                    <meta name="image" content="throw_bag.jpg" />
                    <meta name="author" content="Trashback" />
                    <meta name="version" content="v2.0" />
                </Helmet>
            </React.Fragment>
        )
    }
}

export default withTranslation()(PageHelmet);
