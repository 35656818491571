import React, { Component } from "react";
import {
  FaYoutube,
  FaInstagram,
  FaTelegram,
  FaLinkedinIn,
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";
const SocialShare = [
  { Social: <FiMail />, link: "mailto:info@trashback.net" }, 
  { Social: <FaTelegram />, link: "https://t.me/trashback_net" }, 
  { Social: <FaInstagram />, link: "https://www.instagram.com/trashback_net" },
  { Social: <FaYoutube />, link: "https://www.youtube.com/channel/UC_d8J05AN0-ThyoGfWW8S3g" },
];
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class Footer extends Component {
  
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>{t('footer.slogan')}</span>
                    <h2>
                    {t('footer.cooperation')}
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>{t('footer.contact')}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:info@trashback.net">info@trashback.net</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <ul className="ft-link">
                          <li>
                            <a href="tel:+79688248040">+7(968)8248040</a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Footer);

