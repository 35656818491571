import React, { Component } from "react";

import googlePlay from "../assets/images/brand/googlePlay.png";
import appStore from "../assets/images/brand/appStore.png";
import ruStore from "../assets/images/brand/ruStoreLogo.png";

class AppStore extends Component {
  render() {
    const { branstyle } = this.props;
    return (
      <React.Fragment>
        <ul className={`brand-list ${branstyle}`}>
          <li>
            <a href="https://play.google.com/store/apps/details?id=com.trashback.trashback">
              <img src={googlePlay} alt="Google play TrashBack" />
            </a>
          </li>
          <li>
            <a href="https://apps.apple.com/ru/app/trashback/id1481627273">
              <img src={appStore} alt="App store TrashBack" />
            </a>
          </li>
          <li>
            <a href="https://apps.rustore.ru/app/com.trashback.trashback">
              <img src={ruStore} alt="Ru store TrashBack" />
            </a>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default AppStore;
