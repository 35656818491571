
import { useEffect ,useState, useRef } from 'react';
import React from "react";
import { useLocation, useSearchParams } from 'react-router-dom';
import {products} from "../../assets/shop/products"
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from "react-slick";
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Radio from '@mui/material/Radio';
import ColorPicker from './ColorPicker'
import Button from '@mui/material/Button';
import {cartStorage, blueColor } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);

var sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Product = (props) => {

  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = state || {};
  const [ product, setProduct ] = useState(null);
  const [ color, setColor] = useState(null);
  const {t} = useTranslation();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  useEffect(() =>{
    setProduct(products.filter((val) => val.id == searchParams.get("id"))[0]);
  }, [])


  const addToCart = () =>{
    let cart = localStorage.getItem(cartStorage);
    let productJSON = product;
    productJSON.color = color;
    productJSON.cartId = Math.floor(Math.random() * 100000);;
    try{
      const cartJSON = JSON.parse(cart);
      //console.log(cartJSON.toString())
      cartJSON.push(productJSON)
      //console.log(JSON.stringify(cartJSON))
      localStorage.setItem(cartStorage, JSON.stringify(cartJSON));
      navigate(`${process.env.PUBLIC_URL}/shop`);
    }
    catch
    {
      //console.log("Cart data error");
      let cartJSON = [productJSON];
      //console.log(JSON.stringify(cartJSON));
      localStorage.setItem(cartStorage, JSON.stringify(cartJSON));
      navigate(`${process.env.PUBLIC_URL}/shop`);
    }
  }

  const setSelectedColor = (color) =>{
    //console.log(color);
    setColor(color);
  }

  return (
    <>
    {product != null
    ? <Grid container p={{xs:1, sm:2, md:5}} item>
        <Grid item xs={12} md={4} sx={{ alignContent: 'center' }} alignItems="center" p={2} > 
            {/* <Carousel axis="vertical" showStatus={false} showArrows={true} >
              {product.images.map(item =>
              <div>
                  <img alt="" src="https://i.ibb.co/svRwr5Z/stul.png" />
                  <p className="legend">Legend 1</p>
              </div>
              )}
              </Carousel> */}
                <Slider {...sliderSettings}>
                  {product.images.map(item =>
                    <Box  p={2}>
                        <img alt="" src={item} width={windowSize.current[1]*1.405}/>
                    </Box>
                    )}
                </Slider>
        </Grid>

        <Grid xs={12} md={8} p={2}  item>

          <Grid  xs={12} md={12} container item>
            <Grid xs={9} md={9}  item>  
              <Typography variant="h4" mt={1}>
                {product.productName}
              </Typography>
            </Grid>
            <Grid xs={3} md={3} item> 
              <img src={product.companyLogo}  width={windowSize.current[1]*0.20}  alt='Logo' />
            </Grid>
          </Grid>
        
          <Grid xs={12} md={12} item>
            <Typography variant="h4" color="green">
              {product.productPrice}₽
            </Typography>
          </Grid>

          <Grid  xs={12} md={12} mt={2} item>
            <Typography variant="subtitle" >
              {t('shop.productColor')}
            </Typography>
            <ColorPicker colors={product.colors} setSelectedColor={(color) => setSelectedColor(color) }/>
          </Grid>
        
          <Grid  xs={12} md={12} mt={4} item>
            <Typography variant="subtitle" sx={{color: "#EOF400"}}>
              {product.productDescription}
            </Typography>
          </Grid>

          <Grid container xs={12} md={12} mt={4} item> 
            <Typography variant="subtitle" >
              {t('shop.productParameters')}
            </Typography>
            {Object.entries(product.productParameters).map(([key, value]) => {
                return (
                  <Grid item key={key} container xs={12} md={12} mt={1}> 
                    <Grid  xs={10} md={10} item>
                      <Typography variant="subtitle" gutterBottom>{t("shop."+key)}</Typography>
                    </Grid> 
                    <Grid  xs={2} md={2} item> 
                      <Typography variant="subtitle" gutterBottom>{value}</Typography>
                    </Grid>
                  </Grid>
                )
            })}
          </Grid>
        
          <Grid xs={12} md={12} mt={4} item> 
              <Button onClick={() => addToCart()} fullWidth>{t("shop.productAddToCart")}</Button>
          </Grid>
        </Grid>
    </Grid>
    : <></>}
    </>
  );
};

export default Product;