import React, { Component } from "react";

import brand1 from "../assets/images/brand/vvLogoIcon.png";
import brand2 from "../assets/images/brand/reoLogoIcon.png";
import brand3 from "../assets/images/brand/cocaColaLogoIcon.png";
import brand4 from "../assets/images/brand/glowByteLogoIcon.png";
import brand5 from "../assets/images/brand/dodoLogoIcon.png";
import brand6 from "../assets/images/brand/rusproLogoIcon.png";
import brand7 from "../assets/images/brand/tetrapakLogoIcon.png";
import brand8 from "../assets/images/brand/mkbLogoIcon.png";
import brand9 from "../assets/images/brand/nashDomLogoIcon.png";


class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src={brand1} alt="ВкусВилл" />
          </li>
          <li>
            <img src={brand2} alt="РЭО" />
          </li>
          <li>
            <img src={brand3} alt="Coca-Cola" />
          </li>
          <li>
            <img src={brand4} alt="GlowByte" />
          </li>
          <li>
            <img src={brand5} alt="Dodo pizza" />
          </li>
          <li>
            <img src={brand6} alt="RusPro" />
          </li>
          <li>
            <img src={brand7} alt="Tetrapak" />
          </li>
          <li>
            <img src={brand8} alt="MKB" />
          </li>
          <li>
            <img src={brand9} alt="Hash dom" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
