import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class Portfolio extends Component{
    render(){
    const { t } = this.props;
    const PortfolioList = [
    {
        image: t('home.portfolioCase1Image'),
        category: t('home.portfolioCase1Tag'),
        title: t('home.portfolioCase1'),
        link:  t('home.portfolioCase1Link'),
    },
    {
        image: t('home.portfolioCase2Image'),
        category: t('home.portfolioCase2Tag'),
        title: t('home.portfolioCase2'),
        link:  t('home.portfolioCase2Link'),
    },
    {
        image: t('home.portfolioCase3Image'),
        category: t('home.portfolioCase3Tag'),
        title: t('home.portfolioCase3'),
        link:  t('home.portfolioCase3Link'),
    },
    {
        image: t('home.portfolioCase4Image'),
        category: t('home.portfolioCase4Tag'),
        title: t('home.portfolioCase4'),
        link:  t('home.portfolioCase4Link'),
    },
    {
        image: t('home.portfolioCase5Image'),
        category: t('home.portfolioCase5Tag'),
        title: t('home.portfolioCase5'),
        link:  t('home.portfolioCase5Link'),
    },
    {
        image: t('home.portfolioCase6Image'),
        category: t('home.portfolioCase6Tag'),
        title: t('home.portfolioCase6'),
        link:  t('home.portfolioCase6Link'),
    },
    {
        image: t('home.portfolioCase7Image'),
        category: t('home.portfolioCase7Tag'),
        title: t('home.portfolioCase7'),
        link:  t('home.portfolioCase7Link'),
    },
    {
        image: t('home.portfolioCase8Image'),
        category: t('home.portfolioCase8Tag'),
        title: t('home.portfolioCase8'),
        link:  t('home.portfolioCase8Link'),
    }
    ]
          return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{t('home.portfolioTitle')}</h2>
                                    <p>{t('home.portfolioDescription')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title"><a href={value.link}>{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={value.link}>{t('home.portfolioDetails')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="link-overlay" to={value.link}></a>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Portfolio);