export const promo = [
    {
        "id": 1,
        "priority": 110, 
        "name": "Можно оплатить до 50% заказа Экойнами",
        "image": "https://www.reversevending.co.uk/images/slider/layer/4/full_man.png",
        "URL": "https://i.ibb.co/XpJ7fM9/rvm.jpg",
        "type": "action",
    },
    {
        "id": 2,
        "priority": 90, 
        "name": "Пункты приема отходов из вторичного пластика",
        "image": "https://glamadelaide.com.au/wp-content/uploads/2022/08/TomRa-Aus-reverse-vending-machine.jpeg",
        "URL": "https://i.ibb.co/XpJ7fM9/rvm.jpg",
        "type": "new",
    },
    {
        "id": 3,
        "priority": 100, 
        "name": "Дверные номерки из переработанного пластика",
        "image": "https://i.ibb.co/8rrhkFR/rsz-293da61f-0991-44db-9ed1-b20e8a3aadf5.jpg",
        "URL": "https://i.ibb.co/jTV8mm8/numer1.jpg",
        "type": "news",
    },
    {
        "id": 4,
        "priority": 100, 
        "name": "Индивидуальные изделия для компаний",
        "image": "https://i.ibb.co/BP13gSp/rsz-f152ddb2-3126-458f-9c23-0c4dc74efc2d.jpg",
        "URL": "https://i.ibb.co/XpJ7fM9/rvm.jpg",
        "type": "new",
    },
    {
        "id": 5,
        "priority": 105, 
        "name": "Приборы освещения из вторичного пластика",
        "image": "https://i.ibb.co/7nTCNf1/rsz-11photo-5276097165854363331-y.jpg",
        "URL": "https://i.ibb.co/XpJ7fM9/rvm.jpg",
        "type": "news",
    },
    {
        "id": 6,
        "priority": 100, 
        "name": "Древесно-полимерный композит - материал будущего",
        "image": "https://i.ibb.co/2tgYWX9/rsz-photo-5242381311803508292-y.jpg",
        "URL": "https://i.ibb.co/XpJ7fM9/rvm.jpg",
        "type": "news",
    }
]