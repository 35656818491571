import React, { Component } from "react";

import aboutImg from "../../../assets/images/about/tbWasteParkImage.png";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class About extends Component {
  render() {
    const { t } = this.props;
      return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src={aboutImg} alt="About Images" />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{t('home.aboutUs')}</h2>
                    <p className="description">{t('home.aboutUsDescription')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(About);
