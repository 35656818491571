import React, { Component } from "react";

import icon1 from "../../assets/images/icons/iconApp.png";
import icon2 from "../../assets/images/icons/recycleBottleIcon.png";
import icon3 from "../../assets/images/icons/3dPrinterIcon.png";
import { useTranslation, withTranslation, Trans } from 'react-i18next';



class ServiceOne extends Component {
  render() {
    const { t } = this.props;

    const ServiceList = [
      {
        icon: icon1,
        title: t('home.step1'),
        description: t('home.step1Description'),      
      },
      {
        icon: icon2,
        title: t('home.step2'),
        description: t('home.step2Description'),      
      },
      {
        icon: icon3,
        title: t('home.step3'),
        description: t('home.step3Description'),      
      },
    ];
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  <img src={val.icon} alt="TrashBack" />
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ServiceOne);
