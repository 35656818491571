import { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import { useHistory, redirect } from "react-router-dom";
import {
  cartStorage,
  ecoinBalanseStorage,
  userIdStorage,
  nameStorage,
  emailStorage,
  phoneStorage,
  emailUrl, 
  salesEmail, 
  salesSub,
  maxEcoinPayShare
} from '../../Constants';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);

const Cart = (props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [cart, setCart]  = useState([]);
  const [total, setTotal]  = useState(0);
  const [paymentType, setPaymentType] = useState(0);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [alertDialogIsOpen, setAlertDialogIsOpen] = useState(false);

  const [name, setName]  = useState("");
  const [email, setEmail]  = useState("");
  const [phone, setPhone]  = useState("");
  const [comment, setComment]  = useState("");
  const [address, setAddress]  = useState("");
  const [policyCheck, setPolicyCheck] = useState(false);
  const [ecoinBalance, setEcoinBalance] = useState(100);
  const [userId, setUserId] = useState("");
  const [isDefaultValue, setIsDefaultValue] = useState(false);

  const [maxEcoinPay, setMaxEcoinPay] = useState(0);
  const [ecoinPay, setEcoinPay] = useState(0);
  const [moneyPay, setMoneyPay] = useState(0);

  useEffect(() =>{
    updateCart();
    getUserParameters();
  },[props])

  useEffect(() => {
    if (ecoinBalance > 1) {
      setEcoinMaxValue();
    } 
  }, [ecoinBalance]);

  const updateCart = () => {
    let cart = localStorage.getItem(cartStorage);
    try{
      const cartJSON = JSON.parse(cart);
      setCart(cartJSON);
      //console.log(cartJSON);
      calculateTotal(cartJSON);
      checkCartIsEmpty();
    }
    catch
    {
      console.log("Cart data error");
    }
  }

  const getUserParameters = () =>{
    
    let ecoinBalance = localStorage.getItem(nameStorage);
    console.log(ecoinBalance)

    localStorage.getItem(userIdStorage) != null && setUserId(localStorage.getItem(userIdStorage));
    localStorage.getItem(nameStorage) != null &&  setName(localStorage.getItem(nameStorage));
    localStorage.getItem(emailStorage) != null &&  setEmail(localStorage.getItem(emailStorage));
    localStorage.getItem(phoneStorage) != null &&  setPhone(localStorage.getItem(phoneStorage));
    localStorage.getItem(ecoinBalanseStorage) != null &&  setEcoinBalance(parseInt(localStorage.getItem(ecoinBalanseStorage)));;
    setIsDefaultValue(true);
  }

  const setEcoinMaxValue = () =>{
    setEcoinBalance(parseInt(localStorage.getItem(ecoinBalanseStorage)));
    console.log("Ecoin:" + localStorage.getItem(ecoinBalanseStorage));
    //setMaxEcoinPay(100);
    let ecBalance =  parseInt(localStorage.getItem(ecoinBalanseStorage));
    ecBalance > total*maxEcoinPayShare 
      ? setMaxEcoinPay(parseInt(total*maxEcoinPayShare))
      : setMaxEcoinPay(ecBalance)
  }

  const ecoinPayChange = (val) =>{
    setEcoinPay(val);
    setMoneyPay(total - val);
  }

  const calculateTotal = (products) =>{
    const sum = products.reduce((accumulator, object) => {
      return accumulator + object.productPrice;
    }, 0);
    setTotal(sum);
    setMoneyPay(sum);
  }

  const removeFromCart = (cartId) =>{
    let cartTemp = cart.filter((item) => item.cartId != cartId);
    localStorage.setItem(cartStorage, JSON.stringify(cartTemp));
    updateCart();
    checkCartIsEmpty();
  }

  const checkCartIsEmpty = () =>{
    let cart = localStorage.getItem(cartStorage);
    try{
      const cartJSON = JSON.parse(cart);
      if(cartJSON.length < 1)
      {
        navigate(`${process.env.PUBLIC_URL}/shop`);
      }
    }
    catch
    {
      console.log("Cart data error");
    }
  }

  const completeOrder = () =>{
    if(
      name === "" ||
      phone === "" ||
      email === "" ||
      address === "" ||
      policyCheck == false
      )
    {
      setAlertDialogIsOpen(true);
    }else{
      sendOrder();
      setDialogIsOpen(true);
    }
  }

  const handlePaymentTypeChange = (e) =>{
   // console.log(e)
    setPaymentType(e.target.value);
  }

  const handleAlertDialogClose = () =>{
    setAlertDialogIsOpen(false);

  }

  const handleDialogClose = () =>{
    localStorage.setItem(cartStorage, "");
    setName("");
    setEmail("");
    setPhone("");
    setComment("");
    setAddress("");
    setDialogIsOpen(false);
    updateUserBalance();
    //history.push('/');
    navigate('/');
  }

  const sendOrder = () => {
    let req = {};
    let cartTemp = cart;
    cartTemp = cartTemp.map(o => {
      let obj = Object.assign({}, o);
      delete obj.images;
      delete obj.companyLogo;
      delete obj.productParameters;
      delete obj.recyclableMaterials;
      delete obj.colors;
      delete obj.id;
      delete obj.priority;
      delete obj.productDescription;
      delete obj.cartId;
      return obj;
    });
    req.cart = cartTemp;
    req.total = total;
    req.ecoinPay = ecoinPay;
    req.moneyPay = moneyPay;
    req.comment = comment;
    req.address = address;
    req.paymentType = paymentType;
    axios.get(emailUrl,{   
      params: {
        to: salesEmail,
        sub: salesSub,
        name: name,
        phone: phone,
        email: email,
        request: JSON.stringify(req)
      }})
    .then((response) =>  {
      console.log(response);
      
    })
    .catch((error) =>  {
      console.log(error);
    });
  }

  const updateUserBalance = () =>{
    localStorage.setItem(ecoinBalanseStorage, ecoinBalance-ecoinPay);
  }

  return (
    <Grid xs={12} md={12} item container p={2}>
      <Grid xs={12} md={7} item  p={{xs:0, md:5, lg: 5}}>
        <Typography variant="h5" mb={2}>
          {t("cart")}
        </Typography>
        <Divider />
        {cart.map((item) => 
          <Grid xs={12} md={12} item container mt={2} key={item.cartId}>
            <Grid xs={4} md={4} item> 
                <Typography variant="body1" gutterBottom sx={{lineHeight: "1em"}}>
                  {item.productName}
                </Typography>
              </Grid>
              <Grid xs={3} md={3} item> 
                <Typography variant="body1" gutterBottom>
                  {item.productPrice}₽
                </Typography>
              </Grid>
              <Grid xs={2} md={2} item> 
                <Box
                    sx={{
                        width: 30,
                        height: 30,
                        borderRadius: 6,
                        backgroundColor: item.color
                    }}
                >
                </Box>
              </Grid>
              <Grid xs={3} md={3} item>
                <Button onClick={() => removeFromCart(item.cartId)}>{t("shop.removeFromCart")}</Button>
              </Grid>
            </Grid>    
          )}
        <Grid xs={12} md={12} container item  mt={2}> 
        </Grid>
        <Divider />
        <Grid xs={12} md={12} container item  mt={2}> 
          <Divider/>
          <Grid xs={8} md={8} item>
            <Typography variant="h6">
              {t("shop.total")}
            </Typography>
          </Grid>
          <Grid xs={4} md={4} item>
            <Typography variant="h6">
              {total}₽
            </Typography>
          </Grid>
        </Grid>
        {ecoinBalance > 0 &&  maxEcoinPay != 0 
        ? <Grid xs={12} md={12} container item  mt={2}>
            <Grid xs={8} md={8} item> 
            <Typography variant="h6">
                {t("shop.payEcoin")}
              </Typography>
            </Grid>
            <Grid xs={4} md={4} item>
              <Typography variant="h6">
                {ecoinPay}
              </Typography>
            </Grid>
            <Grid xs={12} md={12} container item>
            <Slider 
              defaultValue={0} 
              min={0}
              max={maxEcoinPay}
              aria-label="Default" 
              valueLabelDisplay="auto" 
              onChange={(e) => ecoinPayChange(e.target.value)}
              color="reoBlue"
              />
            </Grid>
            <Grid xs={8} md={8} item> 
            <Typography variant="h6">
                {t("shop.payMoney")}
              </Typography>
            </Grid>
            <Grid xs={4} md={4} item>
              <Typography variant="h6">
                {moneyPay}₽
              </Typography>
            </Grid>
        </Grid>
        : <></>}
      </Grid>

      {isDefaultValue
      ? <Grid xs={12} md={5} p={{xs:0, md:5, lg: 5}} item> 
        <Typography variant="h5" mb={2}>
            {t("shop.delivery")}
        </Typography>
        <TextField label={t("shop.nameField")} type={"name"} variant="outlined" margin="normal" required fullWidth onChange={(e) => setName(e.target.value)} defaultValue={name}/>
        <TextField label={t("shop.emailField")} type={"email"} variant="outlined" margin="normal" required fullWidth onChange={(e) => setEmail(e.target.value)} defaultValue={email}/>
        <TextField label={t("shop.phoneField")} type={"phone"} variant="outlined" margin="normal" required fullWidth onChange={(e) => setPhone(e.target.value)} defaultValue={phone} />
        <TextField label={t("shop.addressField")} type={"address"} variant="outlined" margin="normal" required fullWidth onChange={(e) => setAddress(e.target.value)}/>
        <TextField label={t("shop.commentField")} variant="outlined" margin="normal" fullWidth onChange={(e) => setComment(e.target.value)}/>
        <FormControl required sx={{ mt: 2, mb: 2 }} fullWidth>
          <InputLabel id="demo-simple-select-required-label">{t("paymentMethodField")}</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={paymentType}
            label={t("paymentMethodField")}
            onChange={handlePaymentTypeChange}
            fullWidth
          >
            <MenuItem value={0}>{t("shop.paymentMethodCardField")}</MenuItem>
            <MenuItem value={1}>{t("shop.paymentMethodWireField")}</MenuItem>
          </Select>
        </FormControl>
        <FormGroup>
          <FormControlLabel control={<Checkbox value={policyCheck} onClick={() => setPolicyCheck(!policyCheck) }/>} label={t("shop.policyField")} />
        </FormGroup>
        <Grid xs={12} md={12} mt={2} item> 
          <Button mt={2} onClick={() => completeOrder()} fullWidth>{t("shop.completeOrder")}</Button>
            <Dialog
              open={dialogIsOpen}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("shop.orderDialogTitle")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("shop.orderDialogText")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} autoFocus>
                  {t("shop.orderDialogButton")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={alertDialogIsOpen}
              onClose={handleAlertDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("shop.orderAlertDialogTitle")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("shop.orderAlertDialogText")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAlertDialogClose} autoFocus>
                  {t("shop.orderAlertDialogButton")}
                </Button>
              </DialogActions>
            </Dialog>
        
        </Grid>
      </Grid>
      : <></>}
    </Grid>
  );
};


export default Cart;