import React , {useState} from 'react';
import emailjs from 'emailjs-com';
import axios from 'axios';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}
function ContactForm({props}) {
    const { t, i18n } = useTranslation();
    const [ result,showresult ] = useState(false);
    const [ email, setEmail ] = useState("");
    const [ phone, setPhone ] = useState("");
    const [ name, setName ] = useState("");
    const [ message, setMessage ] = useState("");

    
    const handleSubmit = (event) => {
        event.preventDefault();
        axios({
            method: 'get',
            url: 'https://tbaf.azurewebsites.net/api/SendSalesEmail',
            params:{
              to: "info@trashback.net",
              name: name,
              email: email,
              phone: phone,
              request: message,
              sub: "From site contact page"
            },
          })
          .then((result) => {console.log(result)})
          .then((error) => {console.log(error)})
      }


    return (
        // <form onSubmit={handleSubmit}>
        <>
            <div className="rn-form-group">
                <input 
                type="text"
                name="name"
                placeholder= {t('contacts.name')}
                required
                onChange={(event) => {setName(event.target.value)}}
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="email"
                name="Еmail"
                placeholder="Email"
                required
                onChange={(event) => {setEmail(event.target.value)}}
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="phone"
                placeholder={t('contacts.phone')}
                required
                onChange={(event) => {setPhone(event.target.value)}}
                />
            </div>
            
            <div className="rn-form-group">
                <textarea 
                name="message"
                placeholder={t('contacts.message')}
                required
                onChange={(event) => {setMessage(event.target.value)}}
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid"  id="mc-embedded-subscribe" onClick={handleSubmit}>{t('contacts.send')}</button>
            </div> 

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div> 
            </>

    )
}

export default withTranslation()(ContactForm);
