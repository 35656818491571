import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";
import { useTranslation, withTranslation, Trans } from 'react-i18next';


class PortfolioRvm extends Component{
    render(){
        const { t } = this.props;
        const PortfolioList = [
        {
            image: t('rvm.portfolioCase1Image'),
            category: t('rvm.portfolioCase1Tag'),
            title: t('rvm.portfolioCase1'),
            link:  t('rvm.portfolioCase1Link'),
        },
        {
            image: t('rvm.portfolioCase2Image'),
            category: t('rvm.portfolioCase2Tag'),
            title: t('rvm.portfolioCase2'),
            link:  t('rvm.portfolioCase2Link'),
        },
        {
            image: t('rvm.portfolioCase3Image'),
            category: t('rvm.portfolioCase3Tag'),
            title: t('rvm.portfolioCase3'),
            link:  t('rvm.portfolioCase3Link'),
        },
        {
            image: t('rvm.portfolioCase4Image'),
            category: t('rvm.portfolioCase4Tag'),
            title: t('rvm.portfolioCase4'),
            link:  t('rvm.portfolioCase4Link'),
        },
        {
            image: t('rvm.portfolioCase5Image'),
            category: t('rvm.portfolioCase5Tag'),
            title: t('rvm.portfolioCase5'),
            link:  t('rvm.portfolioCase5Link'),
        },
        {
            image: t('rvm.portfolioCase6Image'),
            category: t('rvm.portfolioCase6Tag'),
            title: t('rvm.portfolioCase6'),
            link:  t('rvm.portfolioCase6Link'),
        },
        ]
           return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{t('home.portfolioTitle')}</h2>
                                    <p>{t('home.portfolioDescription')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title"><a href={value.link}>{value.title}</a></h4>
                                            {/* <div className="portfolio-button">
                                                <a className="rn-btn" href={value.link}>Подробнее</a>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <a className="link-overlay" to={value.link}></a> */}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(PortfolioRvm);